import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Switch } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/customStyles.css";
import './i18n';
import All from 'routes'




ReactDOM.render(

    <BrowserRouter>
    
        <All />
     
    </BrowserRouter>,
  document.getElementById("root")
);
