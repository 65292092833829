import React from "react";
import { Routes, Route, Navigate} from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import ResetPassword from "views/auth/ResetPassword.js";

export default function Auth() {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-purple-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(https://wallpaper.dog/large/5486501.png)",
            }}
          ></div>
          <Routes>
            <Route path="/signUp/:verifyKey" element={<Login />} />
            <Route path="/forgotPassword/:verifyKey" element={<ResetPassword />} />
            <Route
        path="/"
        element={<Navigate to="/signUp" />}
    />
 </Routes>
      
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
