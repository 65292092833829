


const FeedBackAlert = ({close, text}) => {
    setTimeout(() => {
        close(false)

    }, 3000)
    return (
        <div className="alert">
            <div className="positionAlert">
            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
            <span className="text-xl inline-block mr-5 align-middle">
                <i className="fas fa-bell"></i>
            </span>
            <span className="inline-block align-middle mr-8 ml-2">
                <b className="capitalize">!</b> {text}
            </span>
                <button onClick={() => close(false)}className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none px-4">
                <span>×</span>
            </button>
        </div>
        </div>
    </div>
    )
}

export default FeedBackAlert;