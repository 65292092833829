import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { BrowserRouter, Route, Routes , Outlet, Navigate} from "react-router-dom";

import { CookiesProvider } from 'react-cookie';


import Auth from "layouts/Auth.js";






const SomeRoutes = () => {
 

    return (
        <CookiesProvider>
            {/* add routes with layouts */}
         <Routes>
           
        

            <Route path="/*" element={<Auth  /> }/>
              
      
            
          
       
         </Routes>
        
       </CookiesProvider>
    );
};

export default SomeRoutes;




